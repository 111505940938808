<template>
  <div
    class="request-item d-flex flex-column justify-content-between align-items-center rounded py-4 px-2"
    v-if="item.type !== 'pickup' || canPickup"
  >
    <div class="d-flex justify-content-center align-items-center w-100">
      <div class="request-item-title align-items-center">
        <span class="icon-lable ml-3 font-weight-bold title-text" :class="item.class">{{
          upper
        }}</span>
        <img svg-inline :src="url" style="float: right" alt="Uello" :class="item.type" />
      </div>
      <div class="request-item-info">
        <a :href="item.help" target="_blank" style="float: right">
          <uello-icon icon="information-outline" title="Dúvidas" size="large" color="marine" />
        </a>
      </div>
    </div>

    <small class="text-dark-darken m-0 text-center px-3" v-html="item.text"></small>
    <small
      class="text-primary m-0 text-center px-2"
      v-if="item.type == 'pickup'"
      v-html="$t('requestItems.pickupObs')"
    ></small>

    <div class="d-flex flex-column justify-content-center align-items-center w-100 px-3">
      <a :href="downloadInfo.file" :download="downloadInfo.fileName" ref="lknDownload" />
      <uello-button
        color="gray"
        @click="downloadFile('/modelo.csv', $t('requestItems.downloadModelDeliveryFileName'))"
        fullwidth
        size="large"
        class="w-100"
        v-if="item.type === 'csv' && hasDelivery"
        :text="$t('requestItems.downloadModelButtonDelivery')"
        :title="$t('requestItems.downloadModelButtonDelivery')"
      />
      <uello-button
        color="gray"
        @click="downloadFile('/modelo_pickup.csv', $t('requestItems.downloadModelPickupFileName'))"
        fullwidth
        size="large"
        class="mt-2 w-100"
        v-if="item.type === 'csv' && hasDelivery"
        :text="$t('requestItems.downloadModelButtonPickup')"
        :title="$t('requestItems.downloadModelButtonPickup')"
      />
      <uello-button
        color="red"
        size="large"
        fullwidth
        class="mt-2 w-100"
        @click="selectFileType"
        :class="{ 'mt-2': item.type === 'csv' }"
        :text="$t('requestItems.selectButton')"
      />
    </div>
  </div>
</template>

<script>
import { safeStorage } from '@/utils';
import { UelloButton, UelloIcon } from '@uello/componentello';

export default {
  name: 'request-item',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    UelloButton,
    UelloIcon,
  },
  computed: {
    url() {
      // eslint-disable-next-line
      const image = require(`@/assets/images/icon-${this.item.type}.svg`);

      return image;
    },
    upper() {
      switch (this.item.type) {
        case 'xml':
        case 'csv':
        case 'spot':
          return this.item.type.toUpperCase();
        case 'copy':
          return this.$t('requestItems.copyLabel');
        case 'form-textbox':
          return this.$t('requestItems.formTextbox');
        case 'pickup':
          return this.$t('requestItems.pickupTextbox');
        default:
          return this.item.type;
      }
    },
    allOperations() {
      const { has_childs, partner_childs, operations } = safeStorage.getItem('config');
      const spotOp = 'spot';

      let allOperations = [...operations].filter(op => op.type !== spotOp);

      if (has_childs && partner_childs) {
        allOperations = [
          ...allOperations,
          ...partner_childs.map(a => a.operations).reduce((a, b) => [...a, ...b]),
        ];
      }

      return allOperations.filter(op => op && op.type !== spotOp);
    },
    hasPickup() {
      return !!this.allOperations.find(o => o.type === 'pickup');
    },
    hasDelivery() {
      return !!this.allOperations.find(o => o.type === 'delivery');
    },
    canPickup() {
      const { pickup_section } = safeStorage.getItem('config');

      return pickup_section;
    },
  },
  data() {
    return {
      downloadInfo: {
        file: '',
        fileName: '',
      },
    };
  },
  methods: {
    selectFileType() {
      if (this.item.type === 'spot') {
        this.$router.push('/app/spot-route');
      } else {
        this.$emit('selected-type', this.item.type);
      }
    },
    downloadTimeout() {
      return setTimeout(() => this.$refs.lknDownload.click(), 250);
    },
    downloadFile(file, name) {
      this.downloadInfo.file = file;
      this.downloadInfo.fileName = name;
      this.downloadTimeout();
      clearTimeout(this.downloadTimeout);
    },
  },
};
</script>

<style lang="scss">
.request-item-title {
  float: right;
  width: 60%;
}
.title-text {
  float: right;
  position: relative;
  top: 10px;
}
.request-item-info {
  float: right;
  width: 40%;
  position: relative;
  top: -25px;
}

.request-item {
  background-color: #fafafa;
  border: 1px solid #e3e3e3;
  min-height: 300px;
  flex: 1;

  img.spot,
  img.form-textbox,
  img.pickup {
    width: 50px;
  }

  &:nth-child(2) {
    margin: 0 40px;
  }

  &:nth-child(3) {
    margin: 0 40px 0 0;
  }

  .icon-lable {
    font-size: 1rem;
  }
}
</style>
